<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <v-btn class="ma-1" elevation="2" :color="is_connected? `green`:`default`" block  @click="btn_action" >{{btn_text}}</v-btn>
                </v-col>
            </v-row>
            <transition name="fade">
            <v-row v-if="is_connected">
                <v-col cols="12" md="12" v-if="is_connected" class="text-center">
                     Ξ {{disp_eth(current_balance)}} ETH available 
                </v-col>
                <v-col cols="12" md="12">
                    <v-slider step="5" thumb-label color="#33c487"  v-model="investement_multiplier" ></v-slider>
                </v-col>
                <v-col cols="4">
                        <v-btn @click="investment=0.1" class="ma-1" elevation="2" color="default" small block >Ξ 0.1</v-btn>
                </v-col>
                <v-col cols="4">
                        <v-btn @click="investment=0.3" class="ma-1" elevation="2" color="default" small block >Ξ 0.3</v-btn>
                </v-col>
                <v-col cols="4">
                        <v-btn @click="investment=0.5" class="ma-1" elevation="2" color="default" small block >Ξ 0.5</v-btn>
                </v-col>
                <v-col cols="12" md="12">
                    <!-- {{investement_multiplier}} -->
                    <v-text-field color="white" label="Investment Eth" v-model="investment" outlined rounded dense :hint="`~ USD ${parseFloat(investment)*1600}`"></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                        <v-btn class="ma-1" elevation="2" color="success"  block >BUY</v-btn>
                </v-col>
            </v-row>
            </transition>
        </v-container>
        <!-- {{account}} -->
    </div>
</template>
<script>
//import { ethers } from 'ethers';
// import Web3 from 'web3';
// Begin Component Imports
// End Component Imports
import { mapState,mapActions, mapMutations } from 'vuex'
export default {
    name: 'metamask-connect',
    components:{
    },
    emits:[
    ],
    data(){return {
        btn_text: 'Connect Metamask',
        is_connected : false, 
        account:{'nothing':1},
        provider:undefined,
        signer:undefined,
        current_balance:undefined,
        investment:0.3,
        investement_multiplier:100,


        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        ...mapMutations(['set_active_wallet','set_investement_amt']),
        disp_eth(eth){
            return eth
        },
        disp_addr(addr){
            return `${addr.slice(0,4)}...${addr.slice(38,42)}`
        },
        async connectToMetaMask() {
            if (window.ethereum == undefined){ console.error('Metamask Was Not Found'); return}
            this.provider = new ethers.providers.Web3Provider(window.ethereum);
            this.signer = this.provider.getSigner();
            console.log("Connected to metamask", this.signer);
            await this.provider.send("eth_requestAccounts", []);
            const account = await this.signer.getAddress()
            if(account != ''){
                console.info("Metamask Connected", account)
                this.is_connected = true
                this.btn_text = this.disp_addr(account)
                this.set_active_wallet(account)
                this.account = {address:account, wallet:'metamask'}
                this.getEthBalance(account)

                window.ethereum.on('accountsChanged', (accounts) => {
                    const newAddress = accounts[0];
                    this.btn_text = this.disp_addr(newAddress)
                    this.set_active_wallet(newAddress)
                    console.log('MetaMask address changed:', newAddress);
                    this.getEthBalance(newAddress)
                });
            }
        },



        async btn_action(){
            if(!this.is_connected){ await this.connectToMetaMask() }
            else{}
        },
        async getEthBalance(address) {
        // Initialize ethers.js provider
   

        try {
            // Retrieve the balance
            const balance = await this.provider.getBalance(address);

            // Convert the balance to Ether (ETH)
            const etherBalance = ethers.utils.formatEther(balance);

            console.log(`ETH balance for address ${address}: ${etherBalance}`);
            this.current_balance = etherBalance
            return etherBalance;
        } catch (error) {
            console.error('Error retrieving ETH balance:', error);
        }
        }


    },
    computed:{
        ...mapState([]),
    },
    watch:{
        investment(newv, oldv){
            this.set_investement_amt(newv)
        },
        investement_multiplier(newv, oldv){
            this.investment = Math.round(1e6*this.current_balance * (newv /100)) / 1000000
            // this.set_investement_amt(newv)

        }
    },
}
</script>
<style scoped lang="scss">
</style>