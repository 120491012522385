<template>
    <div>
        <!-- this is new-tokens-week-stats -->
        <!-- <pre>
            {{data.lq}}
        </pre> -->
        
        <v-container fluid>

            <v-row>
                
                <v-col  v-for="day in week_day_order" :key="day" class="wk_day" @click="change_day(day)">
                    <!-- {{lq_list}} -->
                    <div class="wkhead">
                        {{weekdays[day]}}
                        <br>
                        <small v-if="data.pct_rug[day] > 0">
                            {{data.lq[day].length}} tokens
                        </small>
                        <br>
                        <small v-if="data.pct_rug[day] > 0">

                        {{ disp_pct(data.pct_rug[day]) }}% Rug
                        </small>
                    </div>


                    <div class="tok-wrap">

                        <div v-for="(lq, i) in data.lq[day]" :key="`${lq}_${day}_${i}`" :class="{'token-box-liq':true, 'rug': lq == 0}">

                        </div>
                    </div>
                </v-col>


            </v-row>
                        <v-row>
     
                <v-col cols="12">
                    <v-card class="mx-auto text-center mainsheet" max-width="100%"  >
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-h5 mb-1">  WEEK {{data.wk_info.wk}} OF {{data.wk_info.year}}</v-list-item-title>
                                <v-list-item-subtitle>Started at {{data.wk_info.started_on}}</v-list-item-subtitle>
                            </v-list-item-content>
                           
                        </v-list-item>
                        <v-card-text>
                            <table>
                                <tr>
                                    <th> total coins launched </th>
                                    <td> {{data.agg_stats.total_launched}} </td>
                                </tr>
                                <tr>
                                    <th> total rugs </th>
                                    <td> {{data.agg_stats.total_rugged}} </td>
                                </tr>
                                <tr>
                                    <th> rug pct </th>
                                    <td>{{Math.round(data.agg_stats.pct*100)}} %  </td>
                                </tr>
                            </table>
                        </v-card-text>
                        <v-card-actions>
                 
                        </v-card-actions>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'new-tokens-week-stats',
    components:{
    },
    emits:[
        'day_changed',
        'week_changed'
    ],
    data(){return {
        weekdays: ['Sunday','Monday','Tuesday','Wednessday','thursday','friday','saturday'],
        week_day_order :[1,2,3,4,5,6,0]
        }
    },
    props: { 
        //propName:{type:String,default:''},
        data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
        disp_pct(pct){
            return Math.round(pct*100)
        },
        change_day(newday){
            this.$emit('day_changed', newday)
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.tok-wrap{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
}
.token-box-liq{
    height:7px;
    width:7px;
    margin:1px;
    background-color: white;
    box-shadow: 0px 0px 5px #ffffff86;
}
.rug{ 
    background-color: gray!important;
    box-shadow: 0px 0px 0px transparent!important;

}
.wkhead{
    text-align: center;
    text-transform: capitalize;
    small{
        color:rgba(255, 255, 255, 0.705);
    }
}
.wk_day{
    transition:0.3s;
}
.wk_day:hover{
    background-color: #ffffff23;
}

     .mainsheet{
       background-color: #27272791;
       height:100%;
     }

</style>