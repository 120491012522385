<template>
    <div>
        <div v-if="strategy">
            <v-container fluid>
                    
                <v-row>
                    <v-col cols="12" md="12" >
                            <div style="float:right" class="mr-3" v-if="!edit_mode">  
                                <v-btn class="ma-0 pa-1" elevation="2" outlined color="white" x-small light @click="edit_mode = !edit_mode" >
                                    <v-icon class="ma-0" small >mdi-pencil</v-icon>    
                                </v-btn> 
                            </div>
                            <div v-else>
                                <v-btn class="ma-1" elevation="2" color="default" small  @click="edit_mode = !edit_mode" >close edit </v-btn> 
                            </div>
                    </v-col>
                </v-row>
                <v-row >
    
                    <v-col sm="12" md="12">
                        <v-slide-group multiple show-arrows >
                            <v-slide-item v-for="stage in strategy.stages" :key="stage.id" >
                                            <!-- <v-col cols="12" md="12" > -->
                                                <div class="stage-group">

                                                    <!-- stage  -->
                                                    <h4 @dblclick="open_tab_with(`${db_url}admin/core_trader/strategystage/${stage.id}/change/`)" class="stage-name"> 
                                                        {{stage.name}}     
                                                    <v-icon v-if="edit_mode" class="ma-0" @click="selected.stage_id =stage.id; dialog.add_stage= !dialog.add_stage">mdi-file-edit</v-icon>
                                                   
                                                    </h4>


                                                    <div class="trigger_actions_wrap">
                                                        <div  v-for="(trigger_action,i) in stage.trigger_actions" :key="trigger_action.id" >
                                                            <div v-if="i" style="text-align:center;"> OR </div>
                                                            <div class="trigger_action sheet">
                                                                <div v-if="trigger_action.dynamic_list.input_stream_fnc_name != 'strategy'" @click="" >
                                                                    <!-- <pre>

                                                                        Also look into making new dls automated when doing it with the strat
                                                                        the automatic name should be better, and input stream should be strat 
                                                                        give an option here to change the input stream 
                                                                        
                                                                    </pre> -->
 
                                                                </div>
                                                                <!-- <pre> {{ trigger_action.dynamic_list }} </pre> -->
                                                                <div v-if="trigger_action.dynamic_list">
                                                                    <intel-pair-list-dynamic-list-edit @refresh="do_strategy_get" :set_edit_mode="edit_mode" :pair_dynamic_list_id="trigger_action.dynamic_list.id" :dl_info="trigger_action.dynamic_list" :conditions="trigger_action.dynamic_list.conditions"></intel-pair-list-dynamic-list-edit>
                                                                </div>

                                                

                                                                <div class="trigger_action_action" @dblclick="open_tab_with(`${db_url}admin/core_trader/strategytriggeraction/${trigger_action.id}/change/`)" target="blank">
                                                                    <div> <sup>THEN </sup>  </div>
                                                                    <div class="trigger_action_var"> {{trigger_action.action_option.fnc_name.replaceAll('action_','').replaceAll('_',' ') }} 
                                                                        <sub> 
                                                                            <span v-for = "(k,v) in trigger_action.args_spec" :key="v" > {{k}}</span>
                                                                            <!-- {{trigger_action.args_spec}}  -->
                                                                        </sub>
                                                                        </div>
                                                                    <div> <sup>&</sup>
                                                                        <span v-if="trigger_action.go_to_stage " >  To 
                                                                            {{ trigger_action.go_to_stage.name  }} 
                                                                            <v-icon class="ma-0">mdi-arrow-u-down-right</v-icon> </span>
                                                                        <span v-else >  To Next Stage <v-icon class="ma-0">mdi-arrow-right</v-icon>  </span>
                                                                    </div>
                                                                    <div v-if="edit_mode" 
                                                                        @click="selected.action_id=trigger_action.id; selected.stage= stage; dialog.add_action = true" 
                                                                        > 
                                                                            <v-icon  class="ma-0">mdi-file-edit</v-icon>
                                                                    </div>
                                                                    
                                                                </div>
                                                                <hr>
                                                                
                                                            
                                                            </div>
                                                            
                                                        </div>
                                                        <div v-if="edit_mode" > 
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn  
                                                                    v-bind="attrs" v-on="on" 
                                                                    class="ma-2" elevation="2" color="#e0ce9f" light x-small  fab  
                                                                    @click="selected.action_id=0; selected.stage= stage; dialog.add_action= !dialog.add_action" > 
                                                                        <v-icon>mdi-plus</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span >+ New Action</span>
                                                            </v-tooltip>
                                                        </div>

                                                    </div>
                                                 
                                                </div>
                                         
                                            <!-- </v-col> -->
                            </v-slide-item>
                            <v-slide-item  v-if="edit_mode">
                                    <div > 
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn  v-bind="attrs" v-on="on" class="ma-2" elevation="2" color="white" light x-small  fab 
                                                @click="selected.stage_id=0; dialog.add_stage= !dialog.add_stage" > 
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span >+ New Stage</span>
                                        </v-tooltip>
                                    </div>
                            </v-slide-item>
                        </v-slide-group>

                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- New condition Popup  -->
        <v-dialog color="white" v-model="dialog.add_condition" max-width="70vw" >
            <v-card color="secondary" v-if="selected.action" >
                <v-card-title>
                <span class="text-h5">New Condition</span>
                </v-card-title>
                <v-card-text>
                    <!-- <builder-condition :parentAction="selected.action" :conditionId="selected.condition" @saved="do_strategy_get" ></builder-condition> -->
                    incorportate dynamic lists here 
                </v-card-text>
               
            </v-card>
        </v-dialog>

        <!-- New Action Popup  -->
        <v-dialog v-model="dialog.add_action"  max-width="70vw" >
            <v-card v-if="selected.stage">
                        <v-card-title>
                        <span class="text-h5">Action for stage {{selected.stage.name}}</span>
                        </v-card-title>
                        <v-card-text>
        
                            <builder-action :parentStrategy="strategy" :parentStage="selected.stage" :actionId="selected.action_id" @saved="do_strategy_get"></builder-action>
                        </v-card-text>
                       
            </v-card>
        </v-dialog>

        <!-- New Stage Popup  -->
        <v-dialog v-model="dialog.add_stage"  max-width="70vw" >
            <v-card v-if="strategy">
                <v-card-title>
                <span class="text-h5">New Stage for {{strategy.name}}</span>
                </v-card-title>
                <v-card-text>
                    STRAT {{strategy.id}}
                    <builder-stage :strategy_id="strategy.id" :stageId="selected.stage_id" @saved="do_strategy_get" ></builder-stage>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import IntelPairListDynamicListEdit from '../dynamic_lists/intel-pair-list-dynamic-list-edit.vue'
import BuilderAction from '../intel/builder-action.vue'
import BuilderStage from './builder-stage.vue'
export default {
    name: 'strategy-shower',
    components:{
        BuilderAction,
        BuilderStage,
        IntelPairListDynamicListEdit
    },
    emits:[
    ],
    data(){return {
            edit_mode:false,
            evals:['higher_than'],
            vars:['price_x'],
            actions:{
                'buy':{
                    'vars':'Eth Amount',
                }
            },
            dialog:{
                add_condition:false,
                add_action:false,
                add_stage:false,
            },
            // strategy_id:1,

            strategy:undefined,
            selected:{
                stage:undefined,
                action:undefined,
                condition:0,
                stage_id:0,
                action_id:0,
            }
        }
    },
    props: { 
        strategyId:{type:Number,default:0},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_strategy_get()
    },
    methods:{
        ...mapActions(['model_method']),
        do_strategy_get(){
            if( ! this.strategyId) return 
            this.model_method({method:'state_get',obj_id:this.strategyId, model:'Strategy'}).then((r)=>{
                this.strategy = r.data
                this.dialog={
                    add_condition:false,
                    add_action:false,
                    add_stage:false,
                }
            })
        },
        open_tab_with(url){
            window.open(url, "_blank");
        }
    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
        strategyId(newv,oldV){
            this.do_strategy_get()
        }
    },
}
</script>
<style scoped lang="scss">
// .theme--dark.v-sheet {
//     // background-color: rgba(77, 77, 77, 0.404);
//     border-radius:3px;
//     // background-color: transparent!important;
// }
.strat-header{
    background-color: rgba(255, 255, 255, 0.164);
    display:flex;
    justify-content: space-between;
    border-radius: 10px;
    // margin:2px;
    padding:10px;
}
.trigger_actions_wrap{
    // border:1px solid black;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    .trigger_action{
        margin-top: 10px;
        border-left: 7px solid #ffffff;
        // border:1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex:1;

        .trigger_action_action{
            display:flex;
            flex-direction:column;
            align-items: center;
            justify-content: space-around;
                min-width: 350px;
            background: linear-gradient( 45deg , #ffffff5b, #ffffff1f);
            div{
                margin-right:10px;
            }
            .trigger_action_var{
                font-weight: bold;
                color: #e0ce9f;

            }
        }
        .trigger_action_go_to_step{
            display:flex;
            justify-content: space-around;
            background: linear-gradient( 45deg , #1f84b187, #ffffff38);

        }
        .trigger_action_condition{
            margin-bottom:1em;
            background: linear-gradient( 45deg , #ffffff10, #ffffff10);
            border-radius: 0px 10px 10px 0px;
            flex-direction: column;
            display:flex;
            align-items: center;
            justify-content: space-between;
            div{
                margin:2px;
                height:100%;
                // background-color: rgba(248, 248, 248, 0.05);
                flex:1;
                white-space: pre-line;
                table{
                    td{
                        background-color: transparent;
                        font-weight: bold;
                    }
                }
                
            }
            .trigger_action_condition_title{
                background-color: transparent;
                text-transform: uppercase;
                font-size: 0.9em;
                text-align: center;
            }
     
        }

    }
}
.stage-name{
    text-transform: uppercase !important;
    text-align: center;
    background-color: #7ab1ff1f;
    border-radius: 3px;
}
.stage-group{
    margin:10px;
    background-color:rgba(3, 3, 3, 0.103);
    padding:4px;
    border-radius: 8px;
}
.stage-group:hover{
    box-shadow: 0 0 10px  white;
}

</style>