<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="8">
      
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="4" v-for="pair in data" :key="pair.id" >
                                
                                <!-- <dextools-embed-graph address=""></dextools-embed-graph> -->
                                <v-card class="mx-auto mainsheet text-center" max-width="100%"  >
                                    <v-list-item three-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 mb-1">  {{pair.name}} </v-list-item-title>
                                            <v-list-item-subtitle>{{pair.symbol}}</v-list-item-subtitle>
                                            <v-sparkline  :smooth="16" :gradient="['#1feaea', '#ffd200','#f72047'  ]" :line-width="3" :value="pair.prices.liq_list" auto-draw stroke-linecap="round" ></v-sparkline>
                                            <h3> Ξ  {{pair.liquidity}}</h3>


                                        </v-list-item-content>
                                       
                                    </v-list-item>
                                    <v-card-text>
                                            Created {{pair.ago}} ago. <br>
                                            {{pair.buy_tax}} %  Buy tax , 
                                             {{pair.sell_tax}} %  Sell tax. <br>
                                             <span v-if="pair.last_test"> 
                                                 <span v-if="pair.last_test.conclusions.is_honeypot" style="color:red"> Systems indicate that this is a honeypot. </span>
                                                 <span v-if="pair.last_test.conclusions.is_buytrap" style="color:red"> Systems indicate that this is a buytrap. </span>
                                                 
                                                 <!-- simulated {{pair.last_test.conclusions}} -->
                                                 
                                            </span>
                                             <span v-else style="color:yellow"> Not Simulated</span>
                                             All time high is at {{pair.prices.ath_x}}x , currently its at {{pair.prices.curr_x}}x <br>
                                             <a :href="`https://etherscan.io/address/${pair.currency_address}`" target="blank"> Open Dextools </a> <br>
                                             <a :href="`https://www.dextools.io/app/en/ether/pair-explorer/${pair.pair_address}`" target="blank" > Open Etherscan </a> <br>

                                    <!-- {{pair}} -->
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn small outlined rounded text > update price </v-btn>
                                        <v-btn small outlined rounded text > resimulate </v-btn>
                                        <v-btn small outlined rounded text > select </v-btn>
                                    </v-card-actions>
                                </v-card>
                                
                            </v-col>
              
                        </v-row>
                    </v-container>
                    
                </v-col>
                <v-col cols="12" md="4">
                    <metamask-connect></metamask-connect>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import DextoolsEmbedGraph from './dextools-embed-graph.vue'
import MetamaskConnect from './metamask-connect.vue'
export default {
    name: 'new-tokens-selected-token',
    components:{
        MetamaskConnect,
        DextoolsEmbedGraph
    },
    emits:[
    ],
    data(){return {
        selected_pair:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        // data:{type:Object,default:Object},
        data:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

     .mainsheet{
       background-color: #27272791;
       height:100%;
     }
     a{
        //  text-decoration: none;
         color:white;
     }
</style>