<template>
    <div>
        <v-container fluid >
            <v-row>
                <v-col sm="12" md="12">
                    <div v-if="selected_action">
                        <h2> {{selected_action.name.replaceAll('_',' ')}} </h2>
                    </div>
                    <div v-else>
                        <selector-action @selected="e => selected_action = e"></selector-action>
                    </div>
                </v-col>
                <!-- {{selected_action}} -->
                <v-col cols="12" md="12" v-if="selected_action">
                    <v-container fluid>
                        <v-row  v-for="arg in selected_action.args_spec" :key="arg.name" >
                            <v-col sm="12" md="12">
                                <h3>Condition  specification</h3>
                            </v-col>
        
             
                            <v-col cols="12" md="12">
                                <v-select v-if="arg.type == 'bool' " v-model="arg.value" :items="[true, false]" label="arg.val" dense rounded solo hide-details ></v-select>
                                <v-text-field v-else
                                color="white"
                                :type="decide_type(arg.type)" 
                                :placeholder="arg.example"
                                :label="arg.name.replaceAll('_', ' ')" v-model="arg.value" outlined dense >
                                </v-text-field>
                            </v-col>
                            <v-col sm="12" md="12">
                                     <v-slider v-model="selected_action.slippage_pct" :min="0" :max="100" :step="1" :thumb-size="30" thumb-color="#ffffff00"  thumb-label="always" >
                                        <template v-slot:thumb-label="{ value }">
                                            <div style=" font-size:0.9em;white-space:nowrap;margin-top:30px;"> + {{value}} % </div>
                                        </template>
                                     </v-slider>
                            </v-col>
                            <v-col sm="12" md="12">
                                Then Go To
                                <v-autocomplete 
                                v-model="to_stage" 
                                :items="parentStrategy.stages" 
                                outlined dense
                                item-title="name"  item-text="name" return-object
                                label="Select stage (leave blank to just advance)" 
                                color="white"
                                rounded >
                                    <template v-slot:selection="data">
                                        {{ data.item.name }}
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item-content>{{data.item.name}}</v-list-item-content>
                                    </template>
                                </v-autocomplete>
                                <!-- {{action}} -->
                            </v-col>
                        </v-row>
                        <v-row>

                            <v-col sm="12" md="12">
                                <v-btn class="ma-1" elevation="2" color="default" small block @click="do_action_create">save</v-btn>
                                <v-btn v-if="!create_mode" color="red darken-3" x-small fab style="float:right;" @click="do_delete" ><v-icon class="ma-0">mdi-trash-can</v-icon></v-btn>
                                
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// import { verifyMessage } from 'ethers/lib/utils'
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import SelectorAction from '../selector-action.vue'
export default {
    name: 'builder-action',
    components:{
        SelectorAction
    },
    emits:[
    ],
    data(){return {
        selected_action : undefined,
        to_stage:{name: 'advance', id:0},
        create_mode:true,
        action:undefined,
        }
    },
    props: { 
        parentStrategy:{type:Object,default:Object},
        parentStage:{type:Object,default:Object},
        //data:{type:Object,default:Object},
        actionId:{type:Number,default:0},
    },
    mounted(){
        if(this.actionId != 0 ) this.get_action()
    },
    methods:{
        ...mapActions(['docs_actions_all','strats_crud_action', 'model_method']),
        decide_type(typen){
            if (typen == 'int'){ return 'number'}
            if (typen == '_empty'){ return 'text'}
        },
        decide_placeholder(example){
            return example.split('=')[1]
        },
        do_action_create(){
            this.strats_crud_action(this.action).then(r=>{
                console.log(r.data);
                this.$emit('saved')
                })
        },
        get_action(){
            this.model_method({model:'StrategyTriggerAction',method:'obj_get', obj_id:this.actionId}).then(r=>{
                this.selected_action = r.data
                this.create_mode = false
            })
        },
        do_delete(){
            if(confirm("are you sure you want to delete this ?")){
                this.action.id = -1*this.action.id
                this.do_action_create()
                this.action.id = -1*this.action.id
            }
        }

    },
    computed:{
        ...mapState([]),
  
    },
    watch:{
        actionId(nv,ov){
            if(this.actionId != 0 ) this.get_action()
            this.selected_action = undefined
            this.create_mode = true
        },
        selected_action(nv,ov){
            if(! this.selected_action){return {}}
            this.action = {
                action_option:this.selected_action,
                to_stage:this.to_stage.id,
                stage:this.parentStage.id,
                id:this.actionId,
                
    
            }

        }

    },
}
</script>
<style scoped lang="scss">
</style>