<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="12">
                    <v-text-field label="Stage Name" v-model="stage.name" outlined dense color="white" ></v-text-field>
                <!-- {{stage}} -->
                    <v-btn class="ma-1" elevation="2" color="default" small block @click="do_stage_create">save</v-btn>
                    <v-btn v-if="!create_mode" color="red darken-3" x-small fab style="float:right;"  @click="do_delete" ><v-icon class="ma-0">mdi-trash-can</v-icon></v-btn>
                </v-col>

            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'builder-stage',
    components:{
    },
    emits:[
    ],
    data(){return {
        stage:{
            id:0,
            name:'',
            strategy:0,
        },
        create_mode:true,
        }
    },
    props: { 
        strategy_id:{type:Number,default:Number},
        stageId:{type:Number,default:0},
        //list:{type:Array,default:Array},
    },
    mounted(){
        if(this.stageId != 0){this.get_stage()}
        this.stage.id = this.stageId
        this.stage.strategy = this.strategy_id
        if (this.stageId != 0 ){ this.get_stage() }
    
    },
    methods:{
        ...mapActions(['strats_crud_stage','model_method']),
        do_stage_create(){
            
            this.strats_crud_stage(this.stage).then(r=>{
                console.log(r.data);
                this.reset()
                this.$emit('saved')
                })
        },
        get_stage(){
            this.model_method({model:'StrategyStage',method:'obj_get', obj_id:this.stageId}).then(r=>{
                this.stage = r.data
                this.create_mode = false
            })
        },
        
        do_delete(){
            if(confirm("are you sure you want to delete this ?")){
                this.stage.id = -1*this.stage.id
                this.do_stage_create()
                
            }
        },
        reset(){
            this.stage = { id:0, name:'', strategy:this.strategy_id }
            this.create_mode = true
        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        stageId(nv,ov){
            if(this.stageId != 0){this.get_stage()}
            this.reset()
        }
    },
}
</script>
<style scoped lang="scss">
</style>