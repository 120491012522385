<template>
    <div>
        <new-tokens></new-tokens>
    </div>
</template>
<script>
// Begin Component Imports
import NewTokens from './new-tokens.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'new-tokens-view',
    components:
        {
            NewTokens
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>