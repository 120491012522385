<template>
    <div>


    <div v-if="user_data.info">

        <!-- Pair Test Record  -->
        <v-dialog v-model="test_dialog"  max-width="70vw" >
            <v-card v-if="selected_pair">
                        <v-card-title>
                            <span class="text-h5"> Test Record for {{selected_pair.name}}</span>
                            <br>
                        </v-card-title>
                        <v-card-subtitle v-if="selected_pair.last_test">

                                Tested {{selected_pair.last_test.time_ago}} ago 

                        </v-card-subtitle>
                        <v-card-text v-if="selected_pair.last_test">
                            <table>
                                <tr>
                                    <th>Buy success</th>
                                    <td :class="selected_pair.last_test.buy_success? 'success-cell' : 'failed-cell'"> {{selected_pair.last_test.buy_success}}</td>
                                </tr>
                                <tr>
                                    <th>Sell success</th>
                                    <td :class="selected_pair.last_test.sell_success? 'success-cell' : 'failed-cell'"> {{selected_pair.last_test.sell_success}}</td>
                                </tr>
                            </table>
                            <h5> Buy report </h5>
                            <table>
                                <tr>
                                    <th>Expected Tokens</th>
                                    <td>{{selected_pair.last_test.expect_tokens_recieved_on_buy}}</td>
                                </tr>
                                <tr>
                                    <th>Actual Tokens</th>
                                    <td>{{selected_pair.last_test.tokens_recieved_on_buy}}</td>
                                </tr>
                                <tr>
                                    <th>pct of expected recieved</th>
                                    <td :class="(100 * selected_pair.last_test.tokens_recieved_on_buy / selected_pair.last_test.expect_tokens_recieved_on_buy) > 20 ? 'success-cell' : 'failed-cell'" >{{ 100 * selected_pair.last_test.tokens_recieved_on_buy / selected_pair.last_test.expect_tokens_recieved_on_buy}}</td>
                                </tr>
                            </table>

                            <h5> Sell report </h5>
                            <table>
                                <tr>
                                    <th>Expected Eth</th>
                                    <td >{{selected_pair.last_test.expect_eth_recieved_on_sell}}</td>
                                </tr>
                                <tr>
                                    <th>Actual Eth</th>
                                    <td>{{selected_pair.last_test.eth_recieved_on_sell}}</td>
                                </tr>
                                <tr>
                                    <th>pct of expected recieved</th>
                                    <td :class="(100 * selected_pair.last_test.eth_recieved_on_sell / selected_pair.last_test.expect_eth_recieved_on_sell) > 20 ? 'success-cell' : 'failed-cell'" >{{ 100 * selected_pair.last_test.eth_recieved_on_sell / selected_pair.last_test.expect_eth_recieved_on_sell}}</td>
                                </tr>
                            </table>

                            <h5> Gas report </h5>
                            <table>
                                <tr>
                                    <th>buy gas</th>
                                    <td>{{selected_pair.last_test.buy_gas}}</td>
                                </tr>
                                <tr>
                                    <th>approve gas</th>
                                    <td>{{selected_pair.last_test.approve_gas}}</td>
                                </tr>
                                <tr>
                                    <th>sell gas</th>
                                    <td>{{selected_pair.last_test.sell_gas}}</td>
                                </tr>
                                <tr>
                                    <th>total gas</th>
                                    <th>{{selected_pair.last_test.total_gas}}</th>
                                </tr>
                                <tr>
                                    <th>total gas eth est <small>50 gwei</small></th>
                                    <th>{{selected_pair.last_test.total_gas_eth}}</th>
                                </tr>
                            </table>
                            <h5> Conclusions </h5>
                            <table>
                                <tr>
                                    <th>is buy trap</th>
                                    <td :class="! selected_pair.last_test.conclusions.is_buytrap? 'success-cell' : 'failed-cell'" >{{selected_pair.last_test.conclusions.is_buytrap}}</td>
                                </tr>
                                <tr>
                                    <th>is honey pot</th>
                                    <td :class="! selected_pair.last_test.conclusions.is_honeypot? 'success-cell' : 'failed-cell'" >{{selected_pair.last_test.conclusions.is_honeypot}}</td>
                                </tr>

                            </table>
     
                            <v-btn class="ma-1" elevation="2" color="white" light small @click="do_sym_trade(selected_pair.id); test_dialog=false;"> simulate again</v-btn>

                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="test_dialog = false" > Close </v-btn>
                        <v-btn color="blue darken-1" text @click="test_dialog = false" > Save </v-btn>
                        </v-card-actions>
            </v-card>
        </v-dialog>

        

        
        <v-container fluid  >
            <v-row v-if="selected_pairs.length" >
                <v-col sm="12" md="12">
                    <transition name="slide-y-transition">
                        <v-sheet rounded="sm">
                            <new-tokens-selected-token :data="selected_pairs"></new-tokens-selected-token>
                        </v-sheet>
                    </transition>
                </v-col>
            </v-row>
            <v-row v-if="wk_stats">
                <v-col sm="12" md="12">
                    <v-sheet rounded="sm">
                        <new-tokens-week-stats :data="wk_stats" @day_changed="change_day_reload"></new-tokens-week-stats>
                        <!-- <metamask-login></metamask-login> -->
                    </v-sheet>
                </v-col>
            </v-row>
            <!-- new-token-listing   -->
            <v-row>
                <!-- day_stats -->
                <v-col sm="12" md="12">
                    <v-sheet rounded="sm">
                        <v-container fluid>
                            <v-row v-if="latest_pairs" >
                                <v-col sm="12" md="12">
                                    <h3> {{ latest_pairs.length}} Token launches for {{weekdays[current_time.day]}}</h3>
                                    <h4> Ath Stats </h4>
                                    <table>
                                        <tr>
                                            <th>Average</th>
                                            <td>{{day_stats.ath.avg.toFixed(2)}} x </td>
                                        </tr>
                                        <tr>
                                            <th>Standard Deviation</th>
                                            <td>{{day_stats.ath.std.toFixed(2)}} x </td>
                                        </tr>
                                        <tr>
                                            <th>Median</th>
                                            <td>{{day_stats.ath.median.toFixed(2)}} x </td>
                                        </tr>
                                        <tr>
                                            <th>Percentiles</th>
                                            <td>
                                                <table>
                                                    <tr v-for="(xes, percentile) in day_stats.ath.percentiles" :key="xes">
                                                        <th>{{percentile}}</th>
                                                        <td>{{xes.toFixed(2)}} x</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col cols="12" md="12" v-if="latest_pairs_filtered">
                                        <table class="new-pair-table">
                                            <tr>
                                                <th>id</th>
                                                <th> Launched </th>
                                                <th> Name </th>
                                                <th> Price </th>
                                                <th> ATH </th>
                                                <th> liquidity </th>
                                                <th> Tests </th>
                                                <th> Simulate</th>
                                                <th> Graph</th>
                                            </tr>
                                            <tr v-for="p in latest_pairs_filtered" :key="p.id" @click="toggle_select_pairs(p)">
                                                <td>{{p.id}}</td>
                                                <td>  {{p.ago}} ago </td>
                                                <td class="tname"> 
                                                    <pair-link-to-page :pair="p"></pair-link-to-page>
                                                    ${{p.symbol}}
                                                    <small>{{p.name}}</small>
                                                </td>
                                                <td>
                                                    <v-sparkline  :smooth="16" :gradient="['#1feaea', '#ffd200','#f72047'  ]" :line-width="3" :value="p.prices.liq_list" auto-draw stroke-linecap="round" ></v-sparkline>
                                                </td>
                                                <td v-if="p.prices">
                                                    {{p.prices.ath_x}} x
                                                </td>
                                                <td> 
                                                    <div class="liquidity-cell">
                                                        {{p.liquidity.toFixed(4)}} ETH 
                                                    </div>
                                                </td>
                                                <!-- <td>{{price_impact_eth(p.liquidity)}} % </td> -->
                                                <td>
                                                    <div style="display:flex; justify-items:center" v-if="p.last_test">
                                                        <!-- {{p.last_test}} -->
                                                        <div v-if="p.last_test.conclusions.is_buytrap" class="failed-cell stand-alone-status"></div>
                                                        <div v-else class="success-cell stand-alone-status "></div>
                                                        <div v-if="p.last_test.conclusions.is_honeypot" class="failed-cell stand-alone-status"></div>
                                                        <div v-else class="success-cell stand-alone-status "></div>
                                                    </div>

                                                </td>
                                                <td>
                                                    <v-btn class="ma-1" elevation="2" color="default"  x-small @click="simulate_action(p)">
                                                        <span v-if="p.last_test"> 
                                                            <span v-if="!p.last_test.buy_success  && !p.last_test.sell_success" style="color:red"> Retry </span>  Report 
                                                        </span>
                                                        <span v-else> Simulate </span>
                                                    </v-btn>
                                                    <!-- <v-btn class="ma-1" elevation="2" color="green" small v-if="p.last_test"> buy</v-btn> -->
                                                </td>
                                                <td>
                                                    <!-- <v-btn @click="selected_dextools_address = p.pair_address;dextools_dialog = true" class="ma-1" elevation="2" color="default" small > Graph </v-btn>  -->
                                                    <!--   -->
                                                </td>
                                        
                                            </tr>
                                        </table>
                                        <dextools-embed-graph v-if="selected_dextools_address" :address="selected_dextools_address" :dialog="dextools_dialog"></dextools-embed-graph>
                                </v-col>
                                <v-col sm="12" md="12" v-else>
                                    <v-progress-circular indeterminate color="white" ></v-progress-circular>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col sm="12" md="12" style="display:flex; justify-content:center">
                                     <v-progress-circular v-if="loading.latest_pairs" indeterminate color="white" ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>

                </v-col>
                
            </v-row>
        </v-container>
    </div>
    <div v-else>
        <view-login></view-login>
    </div>

    </div>
</template>
<script>
// Begin Component Imports
import NewTokensSelectedToken from './new-tokens-selected-token.vue'
import NewTokensWeekStats from './new-tokens-week-stats.vue'
import PleaseLogIn from './view-login.vue'
import StrategyShower from './agents/strategy-shower.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import DextoolsEmbedGraph from './dextools-embed-graph.vue'
import GeneralGameCard from './general-game-card.vue'
import MetamaskLogin from './metamask-login.vue'
import PairLinkToPage from './pair-link-to-page.vue'
export default {
    name: 'new-tokens',
    components:{
        NewTokensWeekStats,
        NewTokensSelectedToken,
        StrategyShower,
        PleaseLogIn,
        DextoolsEmbedGraph,
        GeneralGameCard,
        MetamaskLogin,
        PairLinkToPage,
    },
    emits:[   ],
    data(){return {
        show_sym_log:false,
        latest_pairs:[],
        eth_trade_size:0.3,
        sym_response:undefined,
        loading:{ sym:false, },
        test_dialog:false,
        selected_pair:undefined,
        wk_stats:undefined,
        selected_pairs:[],
        selected_dextools_address:undefined,
        dextools_dialog:false,
        loading:{
            latest_pairs:true,
        },
        current_time:{
            'wk':0,
            'day':0,
            'year':0,
        },
        weekdays: ['Sun','Mon','Tue','Wed','thurs','fri','sat'],
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        // this.get_rgm_time()
        this.do_load_latest_pairs()
        // this.do_sync_with_defi_db()
    
    },
    methods:{
        ...mapActions(['load_latest_pairs','sym_trade','sync_with_defi_db']),
        change_day_reload(newday){
            this.current_time.day = newday
            this.do_load_latest_pairs()

        },
        get_rgm_time(){

                var currentDate = new Date();
                var currentYear = currentDate.getFullYear();
                var weekNumber = getWeek(currentDate);
                var dayNumber = (currentDate.getDay()+6)%7;
                function getWeek(date_now) {
                    var firstDayOfYear = new Date(date_now.getFullYear(), 0, 1);
                    var daysPassed = (date_now - firstDayOfYear) / 86400000; // Number of milliseconds in a day
                    var dayOffset = (firstDayOfYear.getDay() + 6)%7
                    return Math.ceil((daysPassed + dayOffset ) / 7);
                }

                // Output the results
                console.log("Current Year:", currentYear);
                console.log("Week Number:", weekNumber);
                console.log("Day Number of the Week:", dayNumber);
                this.current_time.wk =  weekNumber
                this.current_time.day = dayNumber
                this.current_time.year = currentYear

        },
        do_load_latest_pairs(){
            this.loading.latest_pairs = true
            this.load_latest_pairs({wk_year:`${this.current_time.wk}_${this.current_time.year}`, day:this.current_time.day}).then((r)=>{ 
                this.latest_pairs = r.data.pairs 
                this.day_stats = r.data.day_stats
                this.wk_stats = r.data.wk_stats
                this.current_time.wk = r.data.wk_stats.wk_info.wk
                this.current_time.day = r.data.wk_stats.wk_info.day
                this.current_time.year = r.data.wk_stats.wk_info.year
                this.loading.latest_pairs = false
                })
        },
        do_sync_with_defi_db(){
            this.sync_with_defi_db({n:100}).then((r)=>{ this.latest_pairs = r.data.pairs })
        },
        toggle_select_pairs(pair){
            if(this.selected_pairs.includes(pair)){
                var index = this.selected_pairs.findIndex(obj => obj.id === pair.id);
                if (index !== -1) { this.selected_pairs.splice(index, 1); }
            }else{
                this.selected_pairs.push(pair)
            }
        },
        
        do_sym_trade(pair_id){
            this.loading.sym = true
            this.sym_trade({pair_id:pair_id}).then((r)=>{ 
                this.loading.sym = false
                this.sym_response = r.data 
                this.do_load_latest_pairs()
                })
        },
        get_class_by_test(pair){
            if(!pair.last_test){return 'pair-notest'}
        },
        calc_ago(ts){
            let now = new Date()
            return now - parseFloat(ts)
        },
        price_impact_eth( liq){
            let pi =  Math.round( ((liq/(liq-this.investement_amt)) -1)*100*10)/10
            if(pi < 0){ return 100}
            if(pi > 100){ return 100}
            return pi
        },
        eth_disp(amt){
            amt.toFixed(decimals)
        },
        simulate_action(pair){
            this.selected_pair = pair
            if(pair.last_test){
                if(!pair.last_test.buy_success  && !pair.last_test.sell_success){
                    this.do_sym_trade(pair.id)
                }else{

                    this.test_dialog= !this.test_dialog
                }
            }else{
                this.do_sym_trade(pair.id)
            }
        },
        test_interpret(test){
            if(! test.is_buytrap){
                if(! test.is_honeypot){

                }
            }

        }
    },
    computed:{
        ...mapState(['investement_amt','user_data']),
        latest_pairs_filtered(){
            return this.latest_pairs.filter((p)=>{return p.liquidity})
        },

    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.tname{
      max-width: 100px; /* Adjust the maximum width as needed */
  word-wrap: break-word;
}
.success-cell{
    background-color: green;


}
.failed-cell{
    background-color:red;
}
.stand-alone-status{
    margin:2px;
    height: 10px;
    width: 10px;

}
small{
    color:rgba(255, 255, 255, 0.527);
}
.new-pair-table{
    text-align: center;
}
.liquidity-cell{
    // white-space: pre;
    font-family: "Courier New", Courier, monospace;
}
.pair-card-new{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    text-align: center;
    height:100%;
    width:100%;
    .title{
        .name{
            font-size: 0.5em;
            color: rgba(255, 255, 255, 0.568);
        }
    }
}
</style>