<template>
    <div v-if="actions">
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete 
                    v-model="selected_action" 
                    :items="actions" 
                        
                    item-title="name"  
                    item-text="name" 
                    return-object
                    :label="`Select an Action  `" 
                    outlined
                    rounded
                    hide-details
                    dense
                    color="white"
                    >
                        <template v-slot:selection="data">
                            {{ data.item.name.replaceAll('_',' ') }}
                            <!-- <v-chip v-bind="data.attrs" :input-value="data.selected" color="secondary" small @click="data.select" >{{ data.item.name.replaceAll('_',' ') }}  </v-chip> -->
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-content>{{data.item.domain}} - {{data.item.name.replaceAll('action_','').replaceAll('_',' ')}}</v-list-item-content>
                        </template>
                    </v-autocomplete>
                    
                </v-col>
                <v-col sm="12" md="12">
                    <!-- {{selected_action}} -->
                </v-col>
            </v-row>
        </v-container>
        <!-- {{actions}} -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'selector-action',
    components:{
    },
    emits:[
        'selected'
    ],
    data(){return {
        actions:undefined,
        selected_action:undefined,
        action_domain:'',
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_docs_actions_all()
    
    },
    methods:{
        ...mapActions(['docs_actions_all']),
                do_docs_actions_all(){
            this.docs_actions_all().then((r)=>{
                this.actions = r.data
            })
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        selected_action(newV, oldV){
            this.$emit('selected', newV)
        }
    },
}
</script>
<style scoped lang="scss">
</style>