<template>
    <div>
        <div v-if="address">
            <v-btn elevation="2" color="default" small @click="dialog= !dialog">open</v-btn>
            <!-- DT Graph < -->
            <v-dialog v-model="dialog"  max-width="70vw" >
                <v-card>
                            <v-card-title>
                            <span class="text-h5">DT Graph</span>
                            </v-card-title>
                            <v-card-text>
            
                            <iframe id="dextools-widget"
                                    title="DEXTools Trading Chart"
                                    width="100%"
                                    height="400" 
                                    :src="`https://www.dextools.io/widgets/en/ether/pe-light/${address}?theme=dark&chartType=1&chartResolution=30&drawingToolbars=false`">
                                    </iframe>
                            
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :href="`https://www.dextools.io/app/en/ether/pair-explorer/${address}`" 
                                target="blank" color="white" rounded light @click="dialog = false" > Go To Dextools </v-btn>
                            <v-btn color="white" rounded light @click="dialog = false" > Close </v-btn>
                            </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'dextools-embed-graph',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        address:{type:String,default:''},
        dialog:{type:Boolean,default:false},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>